@import url(~/node_modules/swiper/swiper-bundle.min.css);

html {
    scroll-behavior: smooth;
}

ul li:not(:last-child),
ol li:not(:last-child) {
    margin-bottom: 1rem;
}

.videos-swiper-button-prev,
.videos-swiper-button-next,
.vlog-swiper-button-prev,
.vlog-swiper-button-next,
.publications-swiper-button-prev,
.publications-swiper-button-next {
    color: #E67300;
}

.testimonials,
.publications {
    margin: 2.5rem 0;
    overflow: hidden;
}

.c-footer__wdd a {
    color: #ffffff;
}

.before-d-none.c-cta-image-text:before {
    display: none;
}

.footer-links {
    margin: 0 0 3rem 0;
}

.footer-links a {
    color: #ffffff;
    display: block;
}

.footer-links a:hover {
    color: #ffffff;
}

.footer-social-links {
    width: 1.625rem;
}

.c-header__aux {
    display: none;
}

.w-full {
    width: 100% !important;
}

.mt-2__5 {
    margin-top: 2.5rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-2__5 {
    margin-bottom: 2.5rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.border-top-d-none {
    border-top: none !important;
}

.border-bottom-d-none {
    border-bottom: none !important;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

@media(min-width: 48em) {
    .footer-links {
        margin: 0;
    }

    .c-form__item + .c-form__item {
        margin-top: 0.75rem;
    }

    .c-header__aux {
        display: flex;
    }
}

@media(min-width: 64em) {
    .footer-links {
        -webkit-box-flex: 1;
        flex: 1 0 auto;
        padding: 0 2.25rem;
        width: 33.333333%;
    }
}
